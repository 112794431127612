import { Route, Redirect } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';
import Loader from 'react-loaders'
import { connect } from 'react-redux'

import {
    ToastContainer,
} from 'react-toastify';

import WLAuthRoute from '../../authRoute'

import { requestData, requestAuth } from '../helper/request'
import { init_auth, clear_auth } from "../../reducers/Auth"

const Dashboard = lazy(() => import('../../Views/Dashboard'));
const Product = lazy(() => import('../../Views/Produk'));
const Order = lazy(() => import('../../Views/Order'));
const Orders = lazy(() => import('../../Views/Order/kirim'));
const Laporan = lazy(() => import('../../Views/Laporan'));
const UserLogin = lazy(() => import('../../Views/UserPages/Login'));

class AppMain extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            auth: this.props.auth,
            seller: JSON.parse(localStorage.getItem('BP-SL-Auth-Seller')),
            data: []
        };
    }
    componentDidMount() {
        var Auth = localStorage.getItem('BP-SL-Auth')
        var AuthKey = localStorage.getItem('BP-SL-Auth-Key')
        var AuthUser = localStorage.getItem('BP-SL-Auth-Seller')
        if (Auth != null && AuthKey != null && AuthUser != null)
            this.props.init_auth(Auth, AuthKey, JSON.parse(AuthUser))
    }

    request = async (action, data, method) => {
        var AuthKey = await localStorage.getItem('BP-SL-Auth-Key')
        await requestAuth('loginCus', AuthKey)
            .then((response) => {
                if (response.auth === false) {
                    this.props.clear_auth()
                }
            })

        if (action && data) {
            AuthKey = await localStorage.getItem('BP-SL-Auth-Key')

            data = { api_token: AuthKey, ...data }

            return requestData(action, data, method)
        }
    }
    render() {
        return (
            <Fragment>

                {/* Dashboards */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-beat" />
                            </div>
                            <h6 className="mt-3">
                                Mohon Tunggu...
                                <small>Sedang memuat data</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <WLAuthRoute
                        path="/dashboard"
                        component={Dashboard}
                        request={(action, data, method = 'GET') => this.request(action, data, method)} />
                </Suspense>

                {/* Dashboards */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-beat" />
                            </div>
                            <h6 className="mt-3">
                                Mohon Tunggu...
                                <small>Sedang memuat data</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <WLAuthRoute
                        path="/product"
                        component={Product}
                        request={(action, data, method = 'GET') => this.request(action, data, method)} />
                </Suspense>

                {/* Order */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-beat" />
                            </div>
                            <h6 className="mt-3">
                                Mohon Tunggu...
                                <small>Sedang memuat data</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <WLAuthRoute
                        path="/order"
                        component={Order}
                        request={(action, data, method = 'GET') => this.request(action, data, method)} />
                </Suspense>

                {/* Orders */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-beat" />
                            </div>
                            <h6 className="mt-3">
                                Mohon Tunggu...
                                <small>Sedang memuat data</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <WLAuthRoute
                        path="/orders"
                        component={Orders}
                        request={(action, data, method = 'GET') => this.request(action, data, method)} />
                </Suspense>

                {/* Laporan */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-beat" />
                            </div>
                            <h6 className="mt-3">
                                Mohon Tunggu...
                                <small>Sedang memuat data</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <WLAuthRoute
                        path="/laporan-penjualan"
                        component={Laporan}
                        request={(action, data, method = 'GET') => this.request(action, data, method)} />
                </Suspense>

                {/* Users Login*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-beat" />
                            </div>
                            <h6 className="mt-5">
                                Mohon Tunggu...
                                <small>Sedang memuat data</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route
                        path="/signin"
                        render={(props) => {
                            return <UserLogin
                                request={(action, data, method = 'GET') => requestData(action, data, method)}
                                {...props} />
                        }} />
                </Suspense>



                <Route exact path="/" render={() => <Redirect to="/signin" />} />
                <ToastContainer />
            </Fragment>
        )
    }
};

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    seller: state.Auth.seller,
    api_token: state.Auth.api_token,
})

const mapDispatchToProps = dispatch => ({
    init_auth: (api_token, auth_key, data) => dispatch(init_auth(api_token, auth_key, data)),
    clear_auth: () => dispatch(clear_auth()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppMain)
