export const INIT_AUTH = 'AUTH/INIT';
export const SET_AUTH = 'AUTH/SET';
export const CLEAR_AUTH = 'AUTH/CLEAR';

export const init_auth = (auth, api_token, seller) => ({
    type: INIT_AUTH,
    auth: auth,
    api_token: api_token,
    seller: seller
});

export const set_auth = (api_token, seller) => ({
    type: SET_AUTH,
    api_token: api_token,
    seller: seller
});

export const clear_auth = (api_token) => ({
    type: CLEAR_AUTH
});

export default function reducer(state = {
    api_token: '',
    auth: false,
    seller: null
}, action) {
    switch (action.type) {
        case INIT_AUTH:
            return {
                ...state,
                auth: action.auth,
                api_token: action.api_token,
                seller: action.seller
            };

        case SET_AUTH:

            localStorage.setItem('BP-SL-Auth', true)
            localStorage.setItem('BP-SL-Auth-Key', action.api_token)
            localStorage.setItem('BP-SL-Auth-Seller', JSON.stringify(action.seller))

            return {
                ...state,
                auth: true,
                api_token: action.api_token,
                seller: action.seller
            };
        case CLEAR_AUTH:
            localStorage.clear()

            return {
                ...state,
                auth: false,
                api_token: ''
            };
        default:
    }

    return state;
}
