// export const API = "http://localhost:8000/merchant/"
// export const MAIN_HOST = 'http://localhost:8000/'

export const API         = 'https://belanjapasti.com/apps-services/merchant/'
export const MAIN_HOST      = 'https://belanjapasti.com/'

export const ImageEx = 'https://belanjapasti.com/media/image/'
// export const IMG_HOST       = MAIN_HOST+'media/'
export const IMG_HOST       = 'https://belanjapasti.com/media/image/'
export const THUMB_HOST     = MAIN_HOST+'media/image/thumb/'
export const DEFAULT_IMG    = MAIN_HOST+'media/image/default-img.png'
